import React from "react"
import Layout from "@components/kaigai-fx/layout"
import Seo from "@components/seo"

import icon_t_SVG1 from '@images/kaigai-fx/top_t_icon1.svg';

import { SeoContents } from "@components/kaigai-fx/seo-data.js" // Seo情報設定

const FxNotFoundPage = ({ location }) => {

  // Seo情報設定
  const pathName = '404';
  const seoTag = SeoContents({ Path:pathName });

  return (
    <Layout location={location} >
      {seoTag}
      <div className="text-center mb-8 mb-10 mt-10"><img className="mb-5 mx-auto" alt="" src={icon_t_SVG1}/>
        <h2 className="font-semibold tracking-wide text-lg md:text-[18px] text-[#36A7C3]">お探しのページは<br></br>見つかりませんでした</h2>
      </div>
      <div className="max-w-xl mx-auto py-11 md:max-w-5xl px-6 mb-10 text-center text-[16px]">
        <Seo title="404: Not Found" />
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Layout>
  )
}
export default FxNotFoundPage


